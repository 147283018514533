import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import Subjects from "../utils/subjectData";

import { useWebSocket } from "../WebSocketContext";
import customModalStyles from "../styles/customModalStyles";

import "../styles/MySubject.css";

const RacePlay = () => {
  /* 레이스 경기 중 필요한 것들 */
  const [applyEnabledSubjects, setApplyEnabledSubjects] = useState([]); // 버튼 활성화
  const [subjectsState, setSubjectState] = useState([]); // 신청 현황
  const [subjectsSeat, setSubjectSeat] = useState([]); // 여석
  const [canPlay, setCanPlay] = useState(true); // 신청 버튼 누를 수 있나?
  const [roundTimer, setRoundTimer] = useState(15); // 라운드 플레이시간
  const [modalOpen, setModalOpen] = useState(true); // 장막 설치
  const [onProgress, setOnProgress] = useState(false); // 라운드가 진행중이냐?

  /* 게임 시작 시 신청버튼, 여석 기본 셋팅 */
  useEffect(() => {
    const initialApplyEnabled = {};
    const initialSubjectState = {};
    const initialSubjectSeat = {};
    Object.keys(Subjects).forEach((subject) => {
      initialApplyEnabled[subject] = false; // 신청 버튼 비활성화
      initialSubjectState[subject] = "default"; // 버튼 타입 디폴트 : "신청" 가능
      initialSubjectSeat[subject] = Subjects[subject].initialSeat; // 여석
    });
    setApplyEnabledSubjects(initialApplyEnabled);
    setSubjectState(initialSubjectState);
    setSubjectSeat(initialSubjectSeat);
  }, []);

  /* 소켓 준비 */
  const dto = useWebSocket();
  const socket = dto.socket;

  /* 카운트 다운 준비 */
  const [counter, setCounter] = useState(5); // 게임 플레이 카운트

  /* 페이지 이동시 필요한 것들 */
  const navigate = useNavigate();
  const { state } = useLocation();

  /* 수강 신청 성공한 과목 목록 */
  const mySubjects = [];

  console.log(
    "(버튼 활성화, 버튼 타입, 여석, canPlay)",
    applyEnabledSubjects,
    subjectsSeat,
    subjectsState,
    canPlay
  );

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const message = event.data;
        console.log(" *** [play] Received WebSocket message:", message);

        // 비어있는 메세지가 아니라면 && json 데이터라면
        if (message.startsWith("{")) {
          const msgObject = JSON.parse(message);

          switch (msgObject.action) {
            /* 게임 시작 */
            case "RACE_STATE":
              if (msgObject.data.RACE_CURRENT_STATE === "START") {
                setOnProgress(true); // 라운드 진행시켜
                // 신청 버튼 활성화
                const temp = {};
                Object.keys(Subjects).forEach((subject) => {
                  temp[subject] = true;
                });
                setApplyEnabledSubjects(temp);
                setModalOpen(false); // 암막 제거
              }
              break;

            /* 카운트 다운 & 라운드 종료 시간 관리 */
            case "TIMER_COUNT":
              const countLeft = parseInt(
                msgObject.data.RACE_WAIT_TIME_LEFT,
                10
              );
              const playLeft = parseInt(
                msgObject.data.RACE_CURRENT_TIME_LEFT,
                10
              );

              if (!isNaN(countLeft)) {
                setCounter(countLeft);
              }

              if (!isNaN(playLeft)) {
                if (playLeft > 0) {
                  setRoundTimer(playLeft);
                } else if (playLeft === 0) {
                  setOnProgress(false); // 라운드 종료시켜
                  navigate("/racescore", {
                    replace: true,
                    state: {
                      userColor: state.userColor,
                      userId: state.userId,
                      userCharacter: state.userCharacter,
                      userNickname: state.userNickname,
                      mySubjects: mySubjects,
                    },
                  });
                }
              }
              break;

            /* 신청 버튼 클릭한 결과 */
            case "APPLY":
              const resultMsg = msgObject.data;

              // 내가 누른 신청 버튼이라면
              if (resultMsg.USER === state.userNickname) {
                if (resultMsg.RESULT === "SUCCESS") {
                  mySubjects.push(resultMsg.SUBJECT); // 성공 목록에 담기
                }
                // 여석 및 상태 업데이트
                updateSubjectInfo(
                  resultMsg.SUBJECT,
                  parseInt(resultMsg.SEATS_LEFT),
                  resultMsg.RESULT
                );
                setCanPlay(true); // 이제 다른 과목 클릭 가능!
                setModalOpen(false); // 대기창 해제
              } else {
                // 여석 및 상태 업데이트
                updateSubjectInfo(
                  resultMsg.SUBJECT,
                  parseInt(resultMsg.SEATS_LEFT),
                  "UPDATE"
                );
              }
              break;

            default:
              break;
          }
        }
      };
    } else {
      console.log("*** [raceplay] 소켓 연결 끊김***");
    }
  }, [socket]);

  /* 신청 버튼 핸들러 */
  const applyHandler = (subject) => {
    // 유저가 신청 버튼 누른 과목 정보
    const applyData = {
      action: "APPLY",
      data: {
        SUBJECT: subject,
      },
    };
    socket.send(JSON.stringify(applyData, null, 2));
    setCanPlay(false); // 다른 과목 클릭 금지!
    setModalOpen(true); // 대기창 걸기
  };

  /* 각 과목에 대한 실시간 업데이트 함수 */
  const updateSubjectInfo = (title, newSeat, type = "default") => {
    // 과목 키 찾기
    let subjectKey = "";
    for (const key in Subjects) {
      if (Subjects[key].title === title) {
        subjectKey = key; // title이 일치하는 키를 반환
      }
    }

    if (subjectKey.length > 0) {
      // 1. 여석 업데이트
      setSubjectSeat((prevState) => {
        const updatedSeats = { ...prevState };
        updatedSeats[subjectKey] = newSeat;
        return updatedSeats;
      });

      // 2. 상태 업데이트
      if (type === "SUCCESS" || type === "FAIL") {
        // 성공/실패 처리
        setSubjectState((prevState) => {
          const updatedState = { ...prevState };
          updatedState[subjectKey] = type.toLowerCase();
          return updatedState;
        });

        // 같은 버튼 이중 클릭 막기
        setApplyEnabledSubjects((prevState) => {
          const updatedState = { ...prevState };
          updatedState[subjectKey] = false;
          return updatedState;
        });
      } else if (newSeat === 0) {
        // 새로운 여석이 0일 때 마감처리하기
        setSubjectState((prevState) => {
          const updatedState = { ...prevState };
          if (prevState[subjectKey] === "success") {
            updatedState[subjectKey] = "success"; // 이미 success인 경우 유지
          } else {
            updatedState[subjectKey] = "fail"; // 기본적으로 fail로 처리
          }
          return updatedState;
        });

        // 같은 버튼 이중 클릭 막기
        setApplyEnabledSubjects((prevState) => {
          const updatedState = { ...prevState };
          updatedState[subjectKey] = false;
          return updatedState;
        });
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="main-content-wrapper w-[1300px] h-[600px]  bg-white bg-opacity-70 border-2 border-white backdrop-blur-2xl font-bold text-md font-['NanumSquareNeo'] tracking-wider">
        <div className="mt-2 mb-2 text-md font-['NanumSquareNeo'] ">
          라운드 남은 시간 : {roundTimer}초
        </div>
        <div className="w-[1200px] h-[54px] mb-2 bg-[#D6E0EB] rounded-[10px] custom-shadow text-zinc-500">
          <div className="content-title-wrapper w-[1000px] h-[54px] ml-3">
            <div className="w-[80px] text-center">신청</div>
            <div className="w-[160px] text-center">과목번호-분반</div>
            <div className="w-[200px] text-center">과목명</div>
            <div className="w-[60px] text-center">학점</div>
            <div className="w-[100px] text-center">이수구분</div>
            <div className="w-[60px] text-center">여석</div>
          </div>
        </div>

        {Object.keys(Subjects).map((subject) => (
          <div className="w-[1200px] h-[64px] mb-3 bg-white rounded-[10px] custom-shadow ">
            <div
              className={`My-subject My-subject-${subjectsState[subject]} content-title-wrapper`}
            >
              <button
                className={`My-button  ${
                  applyEnabledSubjects[subject] & canPlay
                    ? ""
                    : "pointer-events-none"
                }`}
                onClick={() => applyHandler(Subjects[subject].title)}
              >
                {subjectsState[subject] === "default"
                  ? "신청"
                  : subjectsState[subject] === "fail"
                  ? "마감"
                  : "완료"}
              </button>
              <div className="My-text w-[160px]">
                {Subjects[subject].classNumber}
              </div>
              <div className="My-text w-[200px]">{Subjects[subject].title}</div>
              <div className="My-text w-[60px]">{Subjects[subject].credit}</div>
              <div className="My-text w-[100px]">
                {Subjects[subject].category}
              </div>
              <div className="My-text w-[60px] ">{subjectsSeat[subject]}</div>
            </div>
          </div>
        ))}
      </div>
      <ReactModal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customModalStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        className="w-[100vw] h-[100vh] flex flex-col justify-center items-center font-['Nunito'] font-extrabold"
      >
        <div className="text-[100px] text-white ">
          {!onProgress && counter > 3 ? (
            <>
              Ready
              <br />
              <p className="text-[60px] font-['NanumSquareNeo']">
                {counter}초 후 시작
              </p>
            </>
          ) : !onProgress ? (
            <p>{counter}</p>
          ) : (
            <p className="text-[60px] font-['NanumSquareNeo'] animate-bounce">
              "결과를 전송하는 중"
            </p>
          )}
        </div>
      </ReactModal>
    </div>
  );
};

export default RacePlay;
