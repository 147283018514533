import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { WebSocketProvider } from "./WebSocketContext";
import Title from "./pages/Title";
import RacePlay from "./pages/RacePlay";
import RaceScore from "./pages/RaceScore";

export const PlayerContext = React.createContext();
function App() {
  const [playerCounter, setPlayerCounter] = useState(0); // 플레이어 수 초기값

  return (
    <WebSocketProvider>
      <PlayerContext.Provider value={{ playerCounter, setPlayerCounter }}>
        <BrowserRouter>
          <div className="App">
            <Routes>
              <Route path="/" element={<Title />}></Route>
              <Route path="/:userInfo" element={<Title />}></Route>
              <Route path="/raceplay" element={<RacePlay />}></Route>
              <Route path="/racescore" element={<RaceScore />}></Route>
            </Routes>
          </div>
        </BrowserRouter>
      </PlayerContext.Provider>
    </WebSocketProvider>
  );
}

export default App;
