import React from "react";
import UserItem from "./UserItem.jsx";

const UserItemList = ({ result }) => {
  const maxCredit = parseInt(result[0].CREDIT, 10); // 1등의 신청 학점

  return (
    <div className="w-[95%] mt-[30px] mb-[30px] flex flex-col items-center overflow-y-auto">
      {result.map((it, index) => (
        <UserItem
          nickname={it.USER}
          characterType={it.CHARACTER}
          credit={it.CREDIT}
          rank={index + 1}
          maxCredit={maxCredit}
        ></UserItem>
      ))}
    </div>
  );
};

export default UserItemList;
