import React from "react";

const SugangItem = ({ state, subject, credit, type }) => {
  return state === "success" ? (
    <div className="flex flex-row justify-around items-center w-[580px] h-[54px] text-sm font-bold">
      <div className="w-[80px] text-center  text-red-500">성공</div>
      <div className="w-[200px] text-center">{subject}</div>
      <div className="w-[60px] text-center">{credit}</div>
      <div className="w-[100px] text-center">{type}</div>
    </div>
  ) : (
    <div className="flex flex-row justify-around items-center w-[580px] h-[54px] text-sm  text-gray-400">
      <div className="w-[80px] text-center">실패</div>
      <div className="w-[200px] text-center">{subject}</div>
      <div className="w-[60px] text-center">{credit}</div>
      <div className="w-[100px] text-center">{type}</div>
    </div>
  );
};

export default SugangItem;
