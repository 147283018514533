const Subjects = {
  DRAMA: {
    title: "연극과 뮤지컬",
    initialSeat: 2,
    credit: 3,
    category: "핵심교양",
    classNumber: "53168-01",
  },
  HANJA: {
    title: "글로벌 한자",
    initialSeat: 1,
    credit: 3,
    category: "핵심교양",
    classNumber: "49968-01",
  },
  ACT: {
    title: "ACT",
    initialSeat: 1,
    credit: 2,
    category: "공통교양",
    classNumber: "49950-01",
  },
  MEDICINE: {
    title: "의약의 역사",
    initialSeat: 2,
    credit: 3,
    category: "핵심교양",
    classNumber: "40554-01",
  },
  ACCOUNT: {
    title: "앙트레프레너십시대의 회계",
    initialSeat: 1,
    credit: 2,
    category: "공통교양",
    classNumber: "42183-01",
  },
  HISTORY: {
    title: "한국사",
    initialSeat: 3,
    credit: 2,
    category: "공통교양",
    classNumber: "42301-01",
  },
};

export default Subjects;
