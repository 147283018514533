import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useWebSocket } from "../WebSocketContext";
import { PlayerContext } from "../App";

const Title = () => {
  /* 소켓 준비 */
  const dto = useWebSocket();
  const socket = dto.socket;
  const initialCount = dto.initialCount;

  /* 전체 참여자 */
  const { playerCounter, setPlayerCounter } = useContext(PlayerContext);
  const [showButton, setShowButton] = useState(false); // 버튼 처음엔 안보여줌

  /* 유니티로부터 전달받은 유저 정보  */
  const params = useParams();
  const [userId, setUserId] = useState(-1); // user Id. 정수형
  const [userCharacter, setUserCharacter] = useState(""); // user character
  const [userNickname, setUserNickname] = useState(""); // user nickname
  const [userColor, setUserColor] = useState(""); // user character color
  let trigger = true;
  /* 유니티가 보낸 url로부터 유저 정보 추출 */
  useEffect(() => {
    if (params && params.userInfo) {
      const info = params.userInfo;
      let user = info.replace(";", "").split("&");

      setUserColor(user[0].split("=")[1]);
      setUserId(parseInt(user[1].split("=")[1], 10));
      setUserCharacter(user[2].split("=")[1]);
      setUserNickname(user[3].split("=")[1]);
    } else {
      console.log("** There is no user information received from Unity. **");
    }
  }, []);

  /* 게임 참여자 수 */
  useEffect(() => {
    if(trigger){
      trigger=false;
      setPlayerCounter(initialCount);
    }
    /* 잔여 시간 카운트다운 */
    if (socket) {
      socket.onmessage = (event) => {
        const message = event.data;
        console.log(" *** [title] Received WebSocket message:", message);

        // 비어있는 메세지가 아니라면 + json 데이터라면
        if (message.startsWith("{")) {
          const msgObject = JSON.parse(message);

          switch (msgObject.action) {
            case "PLAYER_COUNT":
              const countTotalPlayer = parseInt(
                msgObject.data.RACE_EVERY_PLAYER_COUNT,
                10
              );
              if (!isNaN(countTotalPlayer)) {
                setPlayerCounter(countTotalPlayer);
              }
              break;

            default:
              break;
          }
        }
      };
    }
  }, [socket]);

  /* go 버튼 클릭 시 (입장 시) */
  const goHandler = () => {
    // 소켓에게 send
    const userData = {
      action: "USER",
      data: {
        ID: parseInt(userId),
        CHARACTER: userCharacter,
        NICKNAME: userNickname,
      },
    };
    socket.send(JSON.stringify(userData));

    // 카운트다운 페이지로 이동
    navigate("/raceplay", {
      replace: true,
      state: {
        userColor: userColor,
        userId: userId,
        userCharacter: userCharacter,
        userNickname: userNickname,
      },
    });
  };

  const navigate = useNavigate();

  // 3초 후에 버튼 등장!
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 3000);

    // 메모리 누수 방지
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <div className="main-content-wrapper w-[600px] h-[540px] bg-sky-100 bg-opacity-80 rounded-[32px] border-white">
        {showButton && (
          <button
            onClick={goHandler}
            className="enter-button w-[230px] h-[49px] top-[440px] absolute bg-white text-zinc-500 text-xl font-extrabold rounded-[32px] shadow font-['Nunito'] tracking-wide"
          >
            GO!
          </button>
        )}

        <div className="w-[577px] h-[404px] top-[40px] absolute">
          <div className="w-[577px] h-[60px] absolute text-center text-[#416697] text-[30px] font-extrabold font-['NanumSquareNeoB']">
            CAU-Planet 수강 신청 게임
          </div>
          <div className="w-[577px] top-[100px] absolute text-center text-md text-slate-500 font-['NanumSquareNeo'] font-normal tracking-wide">
            <span className="text-lg font-extrabold ">참여인원</span>
            <span className="text-lg"> </span>
            <span className="text-lg font-extrabold">
              : {playerCounter}
              <br />
            </span>
            <span>
              <br />
              지금부터 당신은 치열한 경쟁을 뚫고
              <br />
              장바구니에 담은 6과목 전부
              <span className="font-extrabold text-[#416697] "> 올클 </span>하여
              <br />
              15학점 수강 신청에 성공해야 합니다.{" "}
            </span>
            <span>
              <br />
              <br />
              재빠르게{" "}
            </span>
            <span className="font-extrabold text-[#416697] ">신청 </span>
            <span>
              버튼을 클릭하여 여석을 쟁취하세요!
              <br />
              승자에겐 엄청난 혜택이 기다리고 있을지도?{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Title;
