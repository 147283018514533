import React, { createContext, useContext, useEffect, useState } from "react";

const WebSocketContext = createContext();

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [initialCount, setInitialCount] = useState(0);
  let trigger = true;

  const dto = {
    socket,
    initialCount,
  };

  useEffect(() => {
    const newSocket = new WebSocket(
      "wss://metacau.ingkells.com:8080/sugang-websocket"
    );

    newSocket.onopen = () => {
      console.log("*** React connected !!! ***");
      setSocket(newSocket);
    };

    newSocket.onmessage = (event) => {
      const message = event.data;
      console.log("*** Received message : ", message);

      // 비어있는 메세지가 아니라면 + json 데이터라면
      if (message.startsWith("{") && trigger) {
        trigger = false;
        const msgObject = JSON.parse(message);

        switch (msgObject.action) {
          case "PLAYER_COUNT":
            const countTotalPlayer = parseInt(
              msgObject.data.RACE_EVERY_PLAYER_COUNT,
              10
            );
            if (!isNaN(countTotalPlayer)) {
              setInitialCount(countTotalPlayer);
            }
            break;

          default:
            break;
        }
      }
    };

    newSocket.onerror = (e) => {
      console.log("*** React error : ", e, " ***");
    };

    newSocket.onclose = () => {
      console.log("*** React closed ... ***");
    };

    return () => {
      // 컴포넌트가 언마운트될 때 웹소켓 연결 해제
      newSocket.close();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={dto}>
      {children}
    </WebSocketContext.Provider>
  );
};
