import MyButton from "./MyButton";

const UserItem = ({ nickname, characterType, credit, rank, maxCredit }) => {
  return (
    <div className="relative w-[580px] mb-3 flex flex-row items-center place-content-between font-['NanumSquareNeo'] text-md text-[#292929]  ">
      <div className="w-[350px]  place-content-start flex flex-row items-center">
        <div className="w-[50px] font-extrabold text-md">{rank}</div>
        <div className="mr-8 round-image">
          {characterType === "1" ? (
            <img src="images/puang.png" alt="유저 캐릭터 이미지" />
          ) : (
            <img src="images/nendoroid.png" alt="유저 캐릭터 이미지" />
          )}
        </div>
        <div>{nickname}</div>
      </div>
      <div>
        {parseInt(credit) === maxCredit ? (
          <MyButton text={`${credit}학점`} type="winner"></MyButton>
        ) : (
          <MyButton text={`${credit}학점`} type="loser"></MyButton>
        )}
      </div>
    </div>
  );
};

export default UserItem;
