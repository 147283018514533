import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import UserItemList from "../components/UserItemList";
import SugangList from "../components/SugangList";

import { useWebSocket } from "../WebSocketContext";
import ReactModal from "react-modal";
import customModalStyles from "../styles/customModalStyles";

/* 결과 창 */
const RaceScore = () => {
  /* 유저 정보 가져오기 */
  const { state } = useLocation();
  const userNickname = state.userNickname; // 유저 닉네임
  const userCharacter = state.userCharacter; // 유저 정보
  const mySubjects = state.mySubjects; // 수강 신청 성공한 과목 리스트

  const userInfoString =
    "randomColor=" +
    state.userColor +
    "&userId=" +
    state.userId +
    "&character=" +
    userCharacter +
    "&nickname=" +
    userNickname;

  /* 소켓 준비 */
  const dto = useWebSocket();
  const socket = dto.socket;

  /* 카운트 다운 준비 */
  const [modalOpen, setModalOpen] = useState(true); // 대기창 오픈유무
  const [result, setResult] = useState([]); // 레이스 결과

  /* 결과창 토글 */
  const [showUserItemList, setShowUserItemList] = useState(true);
  const toggleContent = () => {
    setShowUserItemList(!showUserItemList);
  };

  /* 창 전환 준비 */
  const navigate = useNavigate();

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const message = event.data; // string
        console.log(" *** [race score] Received WebSocket message:", message);

        // 비어있는 메세지가 아니라면 && json 데이터라면
        if (message.startsWith("{")) {
          const msgObject = JSON.parse(message);

          switch (msgObject.action) {
            case "RACE_RESULT":
              setResult(msgObject.data); // 결과 보여주고
              setModalOpen(false); // 모달 제거

              break;

            case "TIMER_COUNT":
              const timeLeft = parseInt(msgObject.data.RACE_NEXT_TIME_LEFT, 10);

              // 타이틀 창으로 이동
              if (!isNaN(timeLeft) && timeLeft === 0) {
                navigate("/" + userInfoString, {
                  replace: true,
                });
              }
              break;

            default:
              break;
          }
        }
      };
    }
  }, [socket]);

  return (
    <div>
      <div className="main-content-wrapper w-[1000px] h-[720px] bg-white bg-opacity-50 rounded-[50px] border-2 border-white backdrop-blur-2xl font-['NanumSquareNeo']">
        <div className="w-[200px] h-[720px] flex items-center justify-center left-0 top-0 absolute bg-[#E1F3F8] bg-opacity-60 rounded-[40px] border-white">
          <div className="h-[400px] flex flex-col justify-center items-center">
            {userCharacter === "1" ? (
              <img src="images/puang.png" alt="유저 캐릭터 이미지" />
            ) : (
              <img src="images/nendoroid.png" alt="유저 캐릭터 이미지" />
            )}

            <div className="mt-3 text-[#416697] text-lg font-bold tracking-wide">
              {userNickname ? userNickname : "Default"}
            </div>
          </div>
        </div>

        <div className="absolute w-[800px] right-0 flex flex-col justify-center items-center">
          <div className="text-[#416697] mb-2 text-2xl font-extrabold tracking-wide font-['NanumSquareNeoB']">
            {!modalOpen && showUserItemList
              ? "전체 스코어"
              : `${userNickname}님의 수강신청 내역`}
          </div>
          <div className="relative w-[650px] h-[600px] flex flex-col items-center bg-gradient-to-r from-[#D9D9D9] to-white  rounded-[30px] shadow-lg">
            {modalOpen ? (
              <ReactModal
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                style={customModalStyles}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
                className="w-[100vw] h-[100vh] flex flex-col justify-center items-center font-['Nunito'] font-extrabold"
              >
                <div className="text-[100px] text-white ">
                  <p className="text-[60px] font-['NanumSquareNeo'] animate-pulse">
                    결과를 집계하는 중..
                  </p>
                </div>
              </ReactModal>
            ) : showUserItemList ? (
              <UserItemList result={result}></UserItemList>
            ) : (
              <SugangList result={mySubjects}></SugangList>
            )}
            <button
              className="absolute bottom-[15px] font-['NanumSquareNeo'] mt-4 bg-white w-[200px] h-[35px] p-1 rounded-[30px] shadow-md text-sm opacity-80"
              onClick={toggleContent}
            >
              {showUserItemList ? "나의 수강신청 내역" : "레이스 결과"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaceScore;
