import React, { FC } from "react";
import "../styles/MyButton.css";

interface MyButtonProps {
  text: string;
  type?: "winner" | "loser" | "default";
}

const MyButton: FC<MyButtonProps> = ({ text, type = "default" }) => {
  const btnType: "winner" | "loser" | "default" = ["winner", "loser"].includes(
    type
  )
    ? type
    : "default";

  return <button className={`MyButton MyButton_${btnType}`}>{text}</button>;
};

export default MyButton;
