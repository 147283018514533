import React from "react";
import SugangItem from "./SugangItem";

const SugangList = ({ result }) => {
  // 기본 데이터
  const defaultSubject = [
    { name: "연극과 뮤지컬", credit: 3, type: "핵심교양", state: "fail" },
    { name: "글로벌 한자", credit: 3, type: "핵심교양", state: "fail" },
    { name: "ACT", credit: 2, type: "공통교양", state: "fail" },
    { name: "한국사", credit: 2, type: "공통교양", state: "fail" },
    { name: "의약의 역사", credit: 3, type: "핵심교양", state: "fail" },
    {
      name: "앙트레프레너십시대의 회계",
      credit: 2,
      type: "공통교양",
      state: "fail",
    },
  ];

  // 수강신청 성공한 과목 찾기 및 업데이트
  const updatedSubjects = defaultSubject.map((subject) => {
    if (result.includes(subject.name)) {
      return { ...subject, state: "success" };
    }
    return subject;
  });

  return (
    <div className="w-[90%] mt-[30px] mb-[30px] flex flex-col items-center overflow-y-auto font-['NanumSquareNeo']">
      <div className="flex flex-row justify-around items-center w-[580px] h-[54px] mb-2 font-bold border-b border-black">
        <div className="w-[80px] text-center">신청</div>
        <div className="w-[200px] text-center">과목명</div>
        <div className="w-[60px] text-center">학점</div>
        <div className="w-[100px] text-center">이수구분</div>
      </div>
      {updatedSubjects.map((it, index) => (
        <SugangItem
          state={it.state}
          subject={it.name}
          credit={it.credit}
          type={it.type}
        ></SugangItem>
      ))}
    </div>
  );
};

export default SugangList;
